const header = document.querySelector('.page-header');
const headerScroll = document.querySelector('.page-header-scroll-indicator');
let headerHeight = header.clientHeight;

const updateHeaderHeightProp = () => {
    headerHeight = header.clientHeight;
    document.documentElement.style.setProperty('--header-height', `${headerHeight}px`);
}

const init = () => {
    const obs = new IntersectionObserver(entries => {
        header.classList.toggle('condensed', !entries.filter(e => e.isIntersecting).length);
    });

    obs.observe(headerScroll);

    document.querySelectorAll('.hamburger').forEach(h => h.addEventListener('click', e => {
        h.classList.toggle('is-active');
    }));

    header.querySelector('.page-header__menu-toggle').addEventListener('click', e => {
        header.classList.toggle('mobile-open');
    });

    window.addEventListener('resize', updateHeaderHeightProp);

    updateHeaderHeightProp();
}

export default init;
